import { ADToBS } from "bikram-sambat-js"
import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { Calender } from "./Calender"
import { ConfigProvider } from "./Config"
import "./NepaliDatePicker.scss"
interface DatePickerOptions {
    className?: string
    defaultDate?: string | boolean | undefined
    placeholder?: string
    disabled?: boolean
    showResetDateButton?: boolean
    resetButtonElement?: any
    onDateSelect: (date: string | boolean | undefined) => any
}

const NepaliDatePicker: FunctionComponent<DatePickerOptions> = ({
    className,
    defaultDate,
    placeholder,
    onDateSelect,
    disabled,
    showResetDateButton,
    resetButtonElement,
}) => {
    const [showCalendar, setShowCalendar] = useState(false)
    const dateEl = useRef<HTMLInputElement>(null)
    const today = ADToBS(new Date())
    const [value, setValue] = useState(defaultDate === true ? today : defaultDate)

    const handleOnChange = (date: string | undefined, daySelected: boolean) => {
        setValue(date)
        daySelected ? setShowCalendar(false) : setShowCalendar(true)
    }

    useEffect(() => {
        onDateSelect(value)
    }, [value])

    const clearDate = (defaultDate: string | boolean | undefined) => {
        defaultDate === true ? setValue(today) : setValue(defaultDate)
        setShowCalendar(false)
    }

    return (
        <ConfigProvider>
            <div className="input-group" id="dateInputGroup">
                <input
                    type="text"
                    className={className}
                    readOnly={true}
                    ref={dateEl}
                    value={value || ""}
                    disabled={disabled}
                    onClick={() => setShowCalendar(!showCalendar)}
                    placeholder={placeholder}
                />
                {showResetDateButton && (
                    <div className="input-group-append">
                        <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="resetDateButton"
                            onClick={() => clearDate(defaultDate)}
                        >
                            {resetButtonElement}
                        </button>
                    </div>
                )}
            </div>
            {showCalendar && <Calender value={value || ADToBS(new Date())} onChange={handleOnChange} />}
        </ConfigProvider>
    )
}

NepaliDatePicker.defaultProps = {
    className: "nepali-datepicker",
    disabled: false,
    defaultDate: undefined,
    placeholder: "कृपया मिति चयन गर्नुहोस्।",
    showResetDateButton: false,
    resetButtonElement: "X",
    onDateSelect: () => {},
}

export default NepaliDatePicker
